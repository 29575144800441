import React, { createContext, useContext, useState, useEffect } from 'react';
import { useConfig } from '../components/configContext';

const UserContext = createContext();
const UserUpdateContext = createContext();

export const useUser = () => useContext(UserContext);
export const useUserUpdate = () => useContext(UserUpdateContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const config = useConfig();

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${config.apiUrl}/actor/get`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data); // Actualiza el estado del usuario
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data', error);
    }
  };

  const updateUser = (token) => {
    if (token) {
      fetchUserData(token);
    } else {
      setUser(null); // No hay token, el usuario no está autenticado
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token); // Obtiene datos del usuario al cargar el contexto
    }
  }, []);

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={updateUser}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};
