import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import Footer from '../components/footer';
import Mision from '../img/aboutus/mision.png';
import Logo from '../img/utils/logo2.png';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Aboutus = () => {
  // Hooks de Intersection Observer para cada sección
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <Fragment>
      <motion.div 
        className='w-100 bg-aboutus-video shadow'
        initial={{ opacity: 0 }}
        animate={{ opacity: inView1 ? 1 : 0 }}
        transition={{ duration: 1 }}
        ref={ref1}
      >
        <div className='row justify-content-center'>
          <ReactPlayer
            playing={true}
            controls={true}
            loop={false}
            width="auto"
            url="https://storage.googleapis.com/camcontadores-bucket/aboutus/aboutusvideo.mp4"
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </div>
      </motion.div>

      <motion.div
        className='row w-100 justify-content-center py-5'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView2 ? 1 : 0, y: inView2 ? 0 : 50 }}
        transition={{ duration: 1.2 }}
        ref={ref2}
      >
        <h1 className='text-center underline'>
          ¿Quienes somos?
          <div className='row text-center'>
            <div className="d-flex justify-content-center">
              <hr className="border-3" style={{ width: '30%', borderColor: '#27276b' }} />
            </div>
          </div>
        </h1>
        <div className='container col-9'>
          <p className='text-center text-archive-aboutus'>
            En la Cámara de Contadores y Auditores del Perú somos un equipo comprometido
            con la excelencia en la formación y el desarrollo profesional. Nos especializamos en
            crear cursos innovadores y de alta calidad diseñados para contadores y auditores que
            buscan destacar en sus carreras. Con años de experiencia en el sector, entendemos las
            necesidades del mercado y ofrecemos soluciones educativas que empoderan a nuestros
            miembros, proporcionando las herramientas necesarias para afrontar los desafíos del
            mundo financiero actual.
          </p>
        </div>
      </motion.div>

      <motion.div
        className='bg-aboutus-video text-white py-5 shadow'
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: inView3 ? 1 : 0, x: inView3 ? 0 : -100 }}
        transition={{ duration: 1.2 }}
        ref={ref3}
      >
        <div className='container   '>
          <div className='row  row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-1 row-cols-xxl-2 '> 

          
        <div className='col'>
            <h1 className='text-center underline'>
              Nuestra Misión
              <div className='row text-center'>
                <div className="d-flex justify-content-center">
                  <hr className="border-3" style={{ width: '40%', borderColor: 'white' }} />
                </div>
              </div>
            </h1>
            <p className='text-left text-archive-aboutus'>
              Nuestra misión es proporcionar educación y recursos de vanguardia para contadores y
              auditores, fomentando la excelencia profesional y el desarrollo continuo. Buscamos ser
              el referente en formación especializada, ayudando a nuestros miembros a alcanzar sus
              objetivos profesionales a través de cursos actualizados y relevantes, diseñados para
              fortalecer sus habilidades y conocimientos en un entorno cambiante.
            </p>
          </div>
          <motion.div
            className='col d-flex justify-content-end'
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: inView3 ? 1 : 0.8, opacity: inView3 ? 1 : 0 }}
            transition={{ duration: 1.2 }}
          >
            <img src={Mision} alt="" />
          </motion.div>
          </div>
        </div>
      </motion.div>



      <motion.div
        className='container row py-5'
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: inView4 ? 1 : 0, x: inView4 ? 0 : 100 }}
        transition={{ duration: 1.2 }}
        ref={ref4}
      >
      <div className='row  row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-1 row-cols-xxl-2 '> 

        <div className='col d-flex justify-content-start'>
          <img src={Mision} alt="" />
        </div>
        <div className='col'>
          <h1 className='text-center underline'>
            Nuestra Visión
            <div className='row text-center'>
              <div className="d-flex justify-content-center">
                <hr className="border-3" style={{ width: '40%', borderColor: '#27276b' }} />
              </div>
            </div>
          </h1>
          <p className='text-right text-archive-aboutus'>
            Ser la institución líder en formación para contadores y auditores, reconocida por nuestra
            calidad, innovación y capacidad de adaptación a las demandas del mercado. Aspiramos
            a transformar el panorama educativo del sector financiero, estableciendo nuevos
            estándares de aprendizaje y desarrollo profesional que impulsen el éxito de nuestros
            miembros y contribuyan al crecimiento y sostenibilidad del sector.
          </p>
        </div>
        </div>
      </motion.div>
  
      <motion.div
        className='bg-aboutus-brands py-5'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView5 ? 1 : 0, y: inView5 ? 0 : 50 }}
        transition={{ duration: 1.2 }}
        ref={ref5}
      >
        <div className='row'>
          <h1 className='text-center underline'>
            Marcas Aliadas
            <div className='row text-center'>
              <div className="d-flex justify-content-center">
                <hr className="border-3" style={{ width: '40%', borderColor: '#5b5b5b' }} />
              </div>
            </div>
          </h1>
        </div>
        <div className='row d-flex justify-content-around'>
          {[...Array(6)].map((_, index) => (
            <motion.div
              className='col-md-3 my-2 d-flex justify-content-center'
              key={index}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: inView5 ? 1 : 0.8, opacity: inView5 ? 1 : 0 }}
              transition={{ duration: 1.2, delay: index * 0.2 }}
            >
              <img src={Logo} alt="" />
            </motion.div>
          ))}
        </div>
      </motion.div>

      <Footer />
    </Fragment>
  );
}

export default Aboutus;
