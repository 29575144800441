import React, { Fragment } from 'react';
import '../css/card.css';
import '../js/card';

const ReqCards = ({ requirements, isVisible }) => {
  return (
    <Fragment>
      {requirements.map((requirement, index) => (
        <div 
          key={index} 
          className={` text-archive mx-1 card w-40 my-1 card-animation  ${isVisible ? 'visible' : ''} fade-in-right`}
        >
          <div className="card-body">
            <h5 className="card-title text-center"><i className="bi check bi-check2-circle"></i></h5>
            <p className="card-text text-center white ">{requirement.description}</p>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default ReqCards;
