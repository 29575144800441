import React from 'react';

function Footer() {
  return (
    <footer className="footer text-archive mt-3 py-4 bg-navy text-mustard ">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>Contacto</h5>
            <p>
              Dirección: Calle José Díaz 384 15046 Lima, Perú<br />
              Teléfono: +51 922 488 798<br />
              Email: camaracontadoresauditores@gmail.com
            </p>
          </div>
          <div className="col-md-4">
            <h5>Síguenos</h5>
            <ul className="list-unstyled d-flex">
              <li className="ms-3">
              <a
                href="https://www.facebook.com/share/37Wnm5KRGgQFkaPw/?mibextid=qi2Omg"
                className="text-mustard"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
                
              >
                <i className="bi bi-facebook" style={{ fontSize: '24px' }}></i>
              </a>
              </li>
              <li className="ms-3">
                <a 
                target="_blank"
                href="https://twitter.com" className="text-mustard" aria-label="Twitter">
                  <i className="bi bi-twitter" style={{ fontSize: '24px' }}></i>
                </a>
              </li>
              <li className="ms-3">
                <a target="_blank" href="https://instagram.com" className="text-mustard" aria-label="Instagram">
                  <i className="bi bi-instagram" style={{ fontSize: '24px' }}></i>
                </a>
              </li>
              <li className="ms-3">
                <a target="_blank" href="https://linkedin.com" className="text-mustard" aria-label="LinkedIn">
                  <i className="bi bi-linkedin" style={{ fontSize: '24px' }}></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Enlaces Útiles</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/about" className="text-mustard">Sobre Nosotros</a>
              </li>
              <li>
                <a href="/services" className="text-mustard">Servicios</a>
              </li>
              <li>
                <a href="/support" className="text-mustard">Soporte</a>
              </li>
              <li>
                <a href="/careers" className="text-mustard">Carreras</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-4">
          <span>© 2024 Camara de contadores auditores. Todos los derechos reservados.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
