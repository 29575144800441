
import React, { useContext, useState } from 'react';

const SearchContext = React.createContext();
const SearchUpdateContext = React.createContext();

export const useSearch = () => useContext(SearchContext);
export const useSearchUpdate = () => useContext(SearchUpdateContext);

export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <SearchContext.Provider value={searchTerm}>
      <SearchUpdateContext.Provider value={setSearchTerm}>
        {children}
      </SearchUpdateContext.Provider>
    </SearchContext.Provider>
  );
};
