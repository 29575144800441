import React, { Fragment, useEffect, useRef, useState } from 'react';
import '../css/register.css';
import { Link, useNavigate } from 'react-router-dom';
import Typed from 'typed.js';
import { useConfig } from '../components/configContext';
import Logo from '../img/utils/logo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyles = {
  position: 'fixed',
  top: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 9999
};

function Register() {
  const config = useConfig();
  const navigate = useNavigate();
  const el = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    user: {
      userName: '',
      password: '',
      confirmPassword: ''
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Flag para controlar la ejecución

  useEffect(() => {
    const typed = new Typed('#typedreg', {
      strings: ['Contaduría', 'Finanzas', 'Administración'],
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000
    });

    const cursorElements = document.getElementsByClassName('typed-cursor');
    for (let i = 0; i < cursorElements.length; i++) {
      cursorElements[i].style.color = 'black';
    }

    return () => {
      typed.destroy();
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,20}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (['userName', 'password', 'confirmPassword'].includes(name)) {
      setFormData({
        ...formData,
        user: {
          ...formData.user,
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Evitar múltiples envíos
    if (isSubmitting) return;

    setIsSubmitting(true);

    const { name, surname, user } = formData;
    const { userName, password, confirmPassword } = user;

    if (!name || !surname || !userName || !password || !confirmPassword) {
      toast.error('Todos los campos son obligatorios', { position: 'top-center' });
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(userName)) {
      toast.error('El correo electrónico no es válido', { position: 'top-center' });
      setIsSubmitting(false);
      return;
    }

    if (!validatePassword(password)) {
      toast.error('La contraseña debe tener al menos 8 caracteres, una letra, un número y un carácter especial', { position: 'top-center' });
      setIsSubmitting(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Las contraseñas no coinciden', { position: 'top-center' });
      setIsSubmitting(false);
      return;
    }

    try {
      const apiUrl = `${config.apiUrl}/actor`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (data.data === 1) {
        navigate('/validateemail', { state: { email: formData.user.userName } });
        toast.success('Registro exitoso', { position: 'top-center' });
      } else if (data.data === -1) {
        navigate('/validateemail', { state: { email: formData.user.userName } });
      } else if (data.data === 0) {
        toast.error('Este correo ya está en uso', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Error en la conexión con el servidor', { position: 'top-center' });
    } finally {
      setIsSubmitting(false); // Reiniciar el flag
    }
  };

  return (
    <Fragment>
      <div className='d-flex text-archive justify-content-center align-items-center'>
        <div className='row contenedor w-100 container'>
          <div className='col color d-flex align-items-center justify-custom'>
            <div className='border text-center rounded shadow w-75'>
              <div className='text-center mt-2'>
                <img className='mt-3 img-fluid' src={Logo} alt="Logo" width="100" height="100" />
              </div>
              <div className='container reg1 d-flex flex-column align-items-center mt-4'>
                <form className='form-group w-75 col justify-content-center' onSubmit={handleSubmit}>
                  <input className="form-control logintxtreg mt-3" type="text" placeholder="Nombre" name="name" value={formData.name} onChange={handleChange} />
                  <input className="form-control logintxtreg mt-3" type="text" placeholder="Apellidos" name="surname" value={formData.surname} onChange={handleChange} />
                  <input className="form-control logintxtreg mt-3" type="text" placeholder="Dirección de correo electrónico" name="userName" value={formData.user.userName} onChange={handleChange} />
                  <input className="form-control logintxtreg mt-3" type="password" placeholder="Contraseña" name="password" value={formData.user.password} onChange={handleChange} />
                  <input className="form-control logintxtreg mt-3" type="password" placeholder="Confirma la contraseña" name="confirmPassword" value={formData.user.confirmPassword} onChange={handleChange} />
                  <div className="d-grid gap-2 mt-3 regbtn">
                    <button className="btn btn-outline-success" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Registrando...' : 'Registrarme!'}
                    </button>
                    <Link to='/login' className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">
                      ¿Ya estás registrado?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='col color d-flex align-items-center justify-content-start d-none d-lg-flex'>
            <div className='row text-left color title d-flex align-items-start justify-content-start w-75'>
              <div className='h1 title-reg'> Transformamos tu futuro con cursos audaces en<br /> <span id='typedreg' className='title-reg check  h1'></span> </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer style={toastStyles} />
    </Fragment>
  );
}

export default Register;
