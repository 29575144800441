import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typed from 'typed.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/login.css';
import { useConfig } from '../components/configContext';
import Logo from '../img/utils/logo.png';
import { useUserUpdate } from '../components/userContext';

function Login() {
  const navigate = useNavigate();
  const config = useConfig();
  const updateUser = useUserUpdate(); // Hook para actualizar el usuario

  const el = useRef(null);
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
  });

  useEffect(() => {
    const typed = new Typed('#typed', {
      strings: ['Una Frase.', 'Luego otra', 'y otra mas.'],
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación de campos vacíos
    if (!formData.userName || !formData.password) {
      toast.error('Por favor, complete todos los campos.');
      return;
    }

    try {
      const apiUrl = `${config.apiUrl}/user/validate`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (data.data === "0") {
        toast.error('Credenciales incorrectas');
      } else if (data.data === "2") {
        toast.warn('Email no validado');
        navigate('/validateemail', { state: { email: formData.userName } });
      } else {
        localStorage.setItem('token', data.data);
        updateUser(data.data); // Actualiza el contexto con el nuevo token
        toast.success('Inicio de sesión exitoso');
        navigate('/');
      }
    } catch (error) {
      toast.error('Error en la conexión con el servidor');
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="d-flex border-navy justify-content-center align-items-center contenedor">
          <div className='square border mx-1 shadow row rounded'>
            <div className='col'>
              <div className='text-center mt-5'>
                <img className='img-fluid' src={Logo} alt="Logo" />
              </div>

              <div className='text-center container login1 d-flex flex-column justify-content-between mt-4'>
                <form className='form-group' onSubmit={handleSubmit}>
                  <input
                    className="form-control logintxt mt-3"
                    type="text"
                    placeholder="Dirección de correo electronico"
                    name="userName"
                    value={formData.userName}
                    onChange={handleChange}
                  />
                  <input
                    className="form-control logintxt mt-3"
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <div className="d-grid gap-2 mt-3 loginbtn">
                    <button className="btn btn-outline-success" type="submit">Ingresar</button>
                  </div>
                </form>
                <div>
                  <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover vh-100" href="#">
                    Olvide mi contraseña
                  </a>
                </div>
              </div>
            </div>
            <div className='border-start col d-none d-md-block login2 rounded shadow'>
              <div className='row container mt-5 text-center'>
                <div className='col'>
                  <span className='typed h3' id='typed'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center" // Posición del toast en el centro superior
        autoClose={5000} // Tiempo de autocierre
        hideProgressBar={false} // Mostrar la barra de progreso
        newestOnTop={false} // Mostrar el toast más nuevo encima
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default Login;
