import logo from './logo.svg';
import { Routes,Route } from 'react-router-dom';
import Main from './pages/main'
import Aboutus from './pages/aboutus'
import Courses from './pages/courses'
import Navbar from './components/navbar';
import Login from './pages/login';
import Register from './pages/register';
import ValidateEmail from './pages/validateLogin';
import StudentCourse from './pages/studentCourse';
import CourseVideo from './pages/courseVideo';
import { SearchProvider } from './components/SearchProvider';
function App() {
  return (
    <div className="App">
<SearchProvider>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='/nosotros' element={<Aboutus/>}/>
        <Route path="/course/:id" element={<Courses />} />
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path="/validateemail" element={<ValidateEmail/>} />
        <Route path= "/mycourse" element={<StudentCourse/>}/>
        <Route path= "/view" element={<CourseVideo/>}/>

        
      </Routes>
      </SearchProvider>
  
    </div>
  );
}

export default App;
