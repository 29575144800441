import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '../components/configContext';

const ValidateLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const config = useConfig();
  const { email } = location.state || {};
  const [validationCode, setValidationCode] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const sendVerificationEmail = async () => {
    try {
      const apiUrl = `${config.apiUrl}/user/send/useremail`; 
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userName: email })
      });     
       const data = await response.json();
      if (!response.ok) {
       // throw new Error(data.message || 'Error al enviar el correo de verificación');
      }
    } catch (error) {
     // setResponseMessage('Error al enviar el correo de verificación');
    }
  };

  useEffect(() => {

  if (email) {
    sendVerificationEmail();
  }
  }, []);

  const handleChange = (e) => {
    setValidationCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = `${config.apiUrl}/user/validateEmail`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userName:email, validationCode: validationCode })
      });

      const data = await response.json();
      console.log(data)
      if (data.data) {
        setResponseMessage('Código validado exitosamente');
        // Navegar a la siguiente página o realizar alguna otra acción
        navigate('/login');
      } else {
        setResponseMessage('Codigo erroneo');
      }
    } catch (error) {
      setResponseMessage('Error en la conexión con el servidor');
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center contenedor">
          <div className='border border-info-subtle mx-1 shadow row rounded'>
            <div className='col'>
              <div className='text-center mt-5'>
                <h5>Hemos enviado un email de verificación al {email}</h5>
              </div>
              <div className='text-center container d-flex flex-column justify-content-between mt-4'>
                <form className='form-group' onSubmit={handleSubmit}>
                  <input
                    className="form-control logintxt mt-3"
                    type="text"
                    name="Validate"
                    placeholder="Código"
                    value={validationCode}
                    onChange={handleChange}
                  />
                  <div className="d-grid gap-2 mt-3 loginbtn">
                    <button className="btn btn-outline-success mb-5" type="submit">Validar</button>
                  </div>
                </form>
                {responseMessage && <div className="mt-3">{responseMessage}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ValidateLogin;
