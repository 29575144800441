import React from 'react';
import '../css/main.css'; 
import Logo from '../img/utils/logo2.png'
function Loading() {
  return (

         <img className='loading-image img-fluid' src={Logo} alt="Description of the image" />

  );
}

export default Loading;


