
import React, { Fragment, useState, useEffect } from 'react';
import { useConfig } from '../components/configContext';
import { useSearch } from '../components/SearchProvider';
import Coursecard from '../components/coursecard';
import Categories from '../components/categories';
import Footer from '../components/footer';
import Loading from '../components/Loading';
import Carousel from '../components/carousel';

const Main = () => {
  const config = useConfig();
  const searchTerm = useSearch();
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(-1); 

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const url = `${config.apiUrl}/course/${selectedCategory}/${searchTerm !== '' ? searchTerm : -1}`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCourses(data.data);
        } else {
          console.error('Failed to fetch courses');
        }
      } catch (error) {
        console.error('Error fetching courses', error);
      }
    };

    fetchCourses();
  }, [selectedCategory, searchTerm, config.apiUrl]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId !== null ? categoryId : -1); 
  };

  return (
    <Fragment>
      <div className='container mt-4 '>
        <Carousel />
        <div className='row text-center '>
          <div className="d-flex justify-content-center my-3">
            <hr className="border-3" style={{ width: '100%', borderColor: '#c4b070' }} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 ">
            <h4>Categorías</h4>
            <Categories onCategoryClick={handleCategoryClick} />
          </div>
          <div className='col-md-9'>
            <h4 className='text-center'>Cursos</h4>
            <div className="row row-cols-md-1 row-cols-xxl-3 row-cols-lg-2 row-cols-sm-1 row-cols-1 row-cols-xl-2 g-4 loading-container">
              {courses.length > 0 ? (
                courses.map((course, index) => (
                  <div className="col" key={index}>
                    <Coursecard index={index} course={course} isVisible={true} origin={0} />
                  </div>
                ))
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Main;
