import React, { Fragment, useEffect, useState } from 'react';
import { useConfig } from '../components/configContext';

function CourseModule({ idCourse, onSessionClick, setModuleId, origin, selectedSession }) {
  const [courseModules, setCourseModules] = useState([]);
  const config = useConfig();

  useEffect(() => {
    var api = `${config.apiUrl}/CourseModule/module/sessions/${idCourse}`
    const token = localStorage.getItem('token'); 

    if (origin == 1) {
      api = `${config.apiUrl}/CourseModule/module/sessions/${idCourse}/student`
      const token = localStorage.getItem('token');
    }
    fetch(api,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setCourseModules(data.data);
        } else {
          console.error('Failed to fetch data:', data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }, [idCourse, config.apiUrl]);

  return (
    <Fragment>
      {courseModules.map(module => (
        <div className=" text-archive accordion mt-3" id={`accordion-${module.idModule}`} key={module.idModule}>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${module.idModule}`}
                aria-expanded="true"
                aria-controls={`collapse-${module.idModule}`}
                onClick={() => {
                  if (origin === 1) {
                    setModuleId(module.idModule);
                  }
                }}
              >
                {module.name}
              </button>
            </h2>
            <div
              id={`collapse-${module.idModule}`}
              className="accordion-collapse collapse show"
              data-bs-parent={`#accordion-${module.idModule}`}
            >
              <div className="accordion-body">
                <ul className="list-group">
                  {module.sessions.map(session => (
                    <li
                      className={`list-group-item row d-flex ${selectedSession === session.idSession ? 'active' : ''}`}
                      key={session.idSession}
                      onClick={() => {
                        if (origin === 1) {
                          onSessionClick(module.idModule, session.idSession,session.progress);
                          console.log(module.idModule, session.idSession, session.progress)
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="col-md-9">{session.name}</div>
                      <div className="col-md-3 ms-auto text-end">
                        {session.duration || '1h 5 min'}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}

export default CourseModule;
