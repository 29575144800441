import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/card.css';
import '../js/card';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./progressbars/separator";
import ChangingProgressProvider from "./progressbars/ChanginProgressProvider";

const Coursecard = ({ index, course, isVisible, isStudentView, origin }) => {
  const [animationStarted, setAnimationStarted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => {
        setAnimationStarted(true);
      }, index * 200);

      return () => clearTimeout(timeout);
    }
  }, [index, isVisible]);

  const handleViewCourse = () => {
    navigate(`/course/${course.id}`);
  };

  const handleViewCourseStudent = () => {
    navigate(`/view`, { state: { course } });
  };

  const calculateDiscountedPrice = () => {
    return (course.price * (1 - course.discount / 100)).toFixed(2);
  };

  return (
    <Fragment>
      <div className={`card border-none card-animation rounded-1 mx-auto ${isVisible ? 'visible' : ''} ${animationStarted ? 'fade-in-right' : ''}`}>
        {course.discount > 0 && (
          <div className="discount-badge">
            -{course.discount}%
          </div>
        )}
        <img src={course.imgFile} className="card-img-top" alt={course.name}></img>
        <div className="card-body">
          <h5 className="card-title card-archivo-title">{course.name}</h5>
          <small className="text-body-secondary">Por: {course.teacherFullName}</small>
          <div className={`d-flex my-2 ${origin === 1 ? 'justify-content-center' : 'justify-content-start'} align-items-center`}>
            {origin === 1 ? (
              <div style={{ width: "8rem" }}>
                <CircularProgressbarWithChildren
                  value={Math.round(course.courseProgress)}
                  text={`${Math.round(course.courseProgress)}%`}
                  strokeWidth={10}
                  styles={buildStyles({
                    pathColor: "#27276b",
                    textColor: "#27276b",
                    strokeLinecap: "butt"
                  })}
                >
                  <RadialSeparators
                    count={12}
                    style={{
                      background: "#fff",
                      width: "2px",
                      height: `${10}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
            ) : (
              <>
                {course.price > 0 ? (
                  <>
                    {course.discount > 0 ? (
                      <>
                        <h1 className='text card-archivo-price'>S/{calculateDiscountedPrice()}</h1>
                        <h4 className='mx-2 text-muted card-archivo-price me-2' style={{ textDecoration: 'line-through' }}>S/{course.price}</h4>
                      </>
                    ) : (
                      <h1 className='card-archivo-price'>S/{course.price}</h1>
                    )}
                  </>
                ) : (
                  <h1 className='card-archivo-price-bold'>¡Gratis!</h1>
                )}
              </>
            )}
          </div>
          <div className="d-grid gap-2 mt-3">
            {isStudentView ?  
              <button onClick={handleViewCourseStudent} className="btn btn-outline-success">Mis Clases</button> 
              :
              <button onClick={handleViewCourse} className="btn btn-outline-success">Ver Curso</button>
            }
          </div>    
        </div>
      </div>
    </Fragment>
  );
};

export default Coursecard;
