import React, { useState, useEffect, Fragment } from 'react';
import { useConfig } from '../components/configContext';
import { useSearchUpdate } from '../components/SearchProvider';

const Categories = ({onCategoryClick }) => {
  const setSearchTerm = useSearchUpdate(); 

  const [categories, setCategories] = useState([]);
  const config = useConfig();
  useEffect(() => {
    fetch(`${config.apiUrl}/category`)
      .then(response => response.json())
      .then(data => {
        console.log('API response:', data); // Log the response
        setCategories(data.data || []); // Adjust based on response
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  
  return (
    <Fragment>
      <ul className="text-archive list-group list-group-flush d-flex border rounded shadow">
      <li class="list-group-item list-group-item-action" 
      onClick={() => {
        setSearchTerm(''); 
        onCategoryClick(-1)}
        } >Todos</li>
      <li class="list-group-item list-group-item-action " onClick={() => onCategoryClick(-2)} >En Oferta</li>
        <li class="list-group-item list-group-item-action disabled">Cursos nuevos</li>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Categorías
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              {Array.isArray(categories) && categories.map((category, index) => (
                <li key={index} className="list-group-item list-group-item-action" onClick={() => onCategoryClick(category.idCategory)}
>
                  {category.name} {/* Adjust based on the structure of your API response */}
                </li>
              ))}
            </div>
          </div>
        </div>
      </ul>
    </Fragment>
  );
};

export default Categories;
