import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import CourseModule from '../components/courseModule';
import { useConfig } from '../components/configContext';
import Footer from '../components/footer';
import defaultimg from '../img/utils/perfil_default.jpg';

function CourseVideo() {
  const config = useConfig();
  const location = useLocation();
  const course = location.state?.course;
  const [videoUrl, setVideoUrl] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressprev, setProgressprev] = useState(0);
  const [progressact, setprogressact] = useState(0);

  const handleSessionClick = async (idModule, idSession,progress1) => {
    try {
      
      const token = localStorage.getItem('token');
      const response = await fetch(`${config.apiUrl}/CourseModuleSession/session/video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          IdCourse: course.id,
          IdModule: idModule,
          IdSession: idSession
        })
      });

      if (response.ok) {
        const data = await response.json();
        setVideoUrl(data.data);
        setSelectedSession(idSession);
        setModuleId(idModule);
        setProgressprev(progress1);
        
        console.log(progress1,idModule,idSession)
      } else {
        console.error('Failed to fetch video URL');
      }
    } catch (error) {
      console.error('Error fetching video URL:', error);
    }
  };

  const handleProgress = async (state) => {

    
    const percentage = state.played * 100;
    const minutes = state.playedSeconds / 60;
    var completed = false;
    if (percentage > 80) 
    {
      completed = true;
    }
    
    console.log(`Progreso del video: ${percentage.toFixed(2)}%`);

  if ((percentage > progressprev && percentage > progress +2 )|| percentage === 100 ) {
  
    setProgress(percentage);
    try {
  
      const token = localStorage.getItem('token');
      const response = await fetch(`${config.apiUrl}/CourseModuleSession/student/session/progress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          IdCourse: course.id,
          IdModule: moduleId,
          IdSession: selectedSession,
          Progress: percentage,
          Completed: completed
        })
      });
      setProgressprev(percentage)

      if (!response.ok) {
        console.error('Failed to update progress');
      }
    } catch (error) {
      console.error('Error updating progress:', error);
    }

  }  
  };

  useEffect(() => {
    if (course) {
      const fetchFirstSession = async () => {
        try {
          const token = localStorage.getItem('token');

          const response = await fetch(
            `${config.apiUrl}/CourseModule/module/sessions/${course.id}/student`,
            {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          
          );
          
          const data = await response.json();

          if (data.success && data.data.length > 0) {
            const firstModule = data.data[0];
            const firstSession = firstModule.sessions.length > 0 ? firstModule.sessions[0] : null;
            if (firstSession) {
              await handleSessionClick(firstModule.idModule, firstSession.idSession);
              setModuleId(firstModule.idModule);
              setProgressprev(firstSession.progress);
            }
          }
        } catch (error) {
          console.error('Error fetching modules:', error);
        }
      };

      fetchFirstSession();
    }
  }, [course, config.apiUrl]);

  return (
    <Fragment>
      <div className='d-flex flex-column min-vh-100 mt-5'>
        <div className='container-video flex-grow-1'>
          <div className='row'>
            <div className='col-md-8 h-100'>
              <ReactPlayer
                playing={true}
                controls={true}
                loop={false}
                width="100%"
                height="80%"
                url={videoUrl}
                onProgress={(state) => handleProgress(state)} // Pasar el parámetro adicional
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
              <div></div>
              <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10">
                <div class="progress-bar progress-bar-striped progress-bar-animated" 
                       style={{ width: `${progressprev}%`}}
                ></div>
              </div>
              <div className='row text-center  CourseTitle my-2'>
          
                <h1>{course.name}</h1>
              </div>
              <div className='row text-center my-1'>
                <div className="d-flex justify-content-center ">
                  <hr className="border-3" style={{ width: '80%', borderColor: '#27276b' }} />
                </div>
              </div>
              <div className=" text-archive hstack text-center gap-3 my-2">
                <div className="text-left col-md-1">
                  <img src={defaultimg} className="rounded-circle profile-img" alt="..." />
                </div>
                <div className="  text-left col-md-4">
                  <span className="h6">{course.teacherFullName}</span>
                </div>
                <div className="vr"></div>
                <div className="text-left col">
                  <span className="h6">{course.teacherTitle}</span>
                </div>
              </div>
              <div className='row text-center mt-5 text-archive'>
                <h6>{course.description}</h6>
              </div>
            </div>
            <div className='col-md-4 d-flex flex-column'>
              <div className='flex-grow-1'>
                <h3>Mis Sesiones</h3>
                <CourseModule
                  idCourse={course.id}
                  onSessionClick={(idModule, idSession,progress1) => handleSessionClick(idModule, idSession,progress1)}
                  setModuleId={setModuleId}
                  origin={1}
                  selectedSession={selectedSession}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default CourseVideo;
