import React, { Fragment, useState, useEffect } from 'react';
import Coursecard from '../components/coursecard'
import Loading from '../components/Loading';
import { useConfig } from '../components/configContext';
import Footer from '../components/footer';
import { Outlet, Link, useNavigate } from 'react-router-dom';

function StudentCourse() {
    const config = useConfig();
  const [isVisible, setIsVisible] = useState(false);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
   
    setIsVisible(true);
    const token = localStorage.getItem('token'); // Cambia 'jwt' por la clave con la que guardas el token

    const fetchCourses = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/course/get/student`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCourses(data.data);
          console.log(data)
        } else {
          console.error('Failed to fetch courses');
        }
      } catch (error) {

        navigate('/login');
      }
    };

    fetchCourses();
  }, []); 

  return (
    <Fragment>


    <div className='mt-5 container text-archive'>
    <h4 className='text-center'>Mis cursos</h4>
    <div className='row  my-1'>
                <div className="d-flex justify-content-center ">
                  <hr className="border-3" style={{ width: '100%', borderColor: '#27276b' }} />
                </div>
              </div>
        <div className="row d-flex">
        <div className='col-md-1 mt-5'></div>

          <div className='col-md-10 mt-5'>
            <div className="row row-cols-md-1 row-cols-xxl-4  row-cols-1 row-cols-lg-1 g-4 loading-container">
              {courses.length > 0 ? (
                courses.map((course, index) => (
                  <div className="col" key={index}>
                    <Coursecard index={index} course={course} isVisible={isVisible} isStudentView={true} origin={1} />
                  </div>
                ))
              ) : (
                <Loading/>
              )}
            </div>
          </div>
          <div className='col-md-1 mt-5'></div>

        </div>
        <div className='row  my-5 '>
                <div className="d-flex justify-content-center ">
                  <hr className="border-3" style={{ width: '100%', borderColor: '#27276b' }} />
                </div>
              </div>
      </div>
<Footer></Footer>
    </Fragment>

  )
}

export default StudentCourse
