import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import defaultimg from '../img/utils/perfil_default.jpg';
import '../css/courses.css';
import ReqCards from '../components/requirecards';
import { useConfig } from '../components/configContext';
import ReactPlayer from 'react-player';
import CourseModule from '../components/courseModule';
import Footer from '../components/footer';

function Courses() {
  const { id } = useParams();
  const config = useConfig();
  const [course, setCourse] = useState(null);
  const [requirements, setRequirements] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const token = localStorage.getItem('token'); // Cambia 'jwt' por la clave con la que guardas el token

  useEffect(() => {
    setIsVisible(true);

    const fetchCourse = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/course/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCourse(data.data);
        } else {
          console.error('Failed to fetch course');
        }
      } catch (error) {
        console.error('Error fetching course', error);
      }
    };

    const fetchRequirements = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/courseRequirement/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRequirements(data.data || []);
        } else {
          console.error('Failed to fetch requirements');
        }
      } catch (error) {
        console.error('Error fetching requirements', error);
      }
    };

    fetchCourse();
    fetchRequirements();
  }, [id, config.apiUrl]);

  const handleGetFree = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/Course/enroll/free`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ IdCourse: id }),
      });

      if (response.ok) {
        // Handle successful response
        alert('¡Curso obtenido gratis!');
      } else {
        console.error('Failed to get free course');
        alert('Hubo un problema al intentar obtener el curso gratis.');
      }
    } catch (error) {
      console.error('Error getting free course', error);
      alert('Error al intentar obtener el curso gratis.');
    }
  };

  if (!course) {
    return <p>Cargando curso...</p>;
  }

  return (
    <Fragment>
      <div className="container text-archive ">
        <div className="row container mt-5">
          <div className="col-md-8">
            <div className="p2 text-center">
              <span className="h3">{course.name}</span>
            </div>
            <div className='row text-center'>
                <div className="d-flex justify-content-center mb-3">
                  <hr className="border-3" style={{ width: '80%', borderColor: '#27276b' }} />
                </div>
              </div>
            
            <div className="hstack gap-3">
              <div className="text-left col-md-1">
                <img src={defaultimg} className="rounded-circle profile-img" alt="..." />
              </div>
              <div className="text-left col-md-4">
                <span className="h3">{course.teacher}</span>
              </div>
              <div className="vr"></div>
              <div className="text-left col">
                <span className="h3">{course.teacherTitle}</span>
              </div>
            </div>

            <div className="row mt-5">
              <div className="h5">¿Qué necesitas saber para tomar este curso?</div>
            </div>
            <div className="row row-cols-md-2 row-cols-xxl-3 row-cols-lg-2 row-cols-sm-1 row-cols-3 row-cols-xl-3 d-flex justify-content- ">
              <ReqCards requirements={requirements} isVisible={isVisible} />
            </div>
            <div className="h5 my-4">Modulos :</div>
            <CourseModule idCourse={id} />
          </div>
          <div className="col">
            <div className="card">
              <div className="img-card-top">
                <ReactPlayer playing={true} controls={true} loop={true} width='100%' url={course.fileVideo} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{course.name}</h5>
                <p className="card-text white">{course.description}</p>
                <div className="d-grid gap-2">
                <a href={course.syllabus}      target="_blank"  className="btn btn-outline-success text-bold-archive">
                <i class="w-100 bi bi-filetype-pdf mx-2"></i>
                Ver Brochure
                            </a>
                  {course.price === 0 ? (
                        <button onClick={handleGetFree} className="btn btn-outline-success text-bold-archive">
                              ¡Obtener gratis!
                            </button>
                  ) : (
               
                             <a href={`https://wa.me/+51986394402?text=Hola%20estoy%20interesado%20en%20el%20curso%20${encodeURIComponent(course.name)}`} className="text-bold-archive btn btn-outline-success">
                             <i className="bi bi-whatsapp mx-1"></i>
                             Comprar
                           </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Courses;
