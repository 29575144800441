import React, { createContext, useContext } from 'react';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const config = {
    apiUrl: 'https://camarac12-001-site1.ftempurl.com',
    //apiUrl: 'https://localhost:44383',
  };

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
