import React, { Fragment, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useUser, useUserUpdate } from './userContext'; 
import Logo from '../img/utils/logo2.png';
import { useSearchUpdate } from '../components/SearchProvider'
const Navbar = () => {
  const user = useUser();
  const setUser = useUserUpdate();
  const navigate = useNavigate();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const setSearchTerm = useSearchUpdate();

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      const searchTerm = e.target.value;
      setSearchTerm(searchTerm);
      navigate(`/`);


    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
    setIsOffcanvasOpen(false);
  };

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };

  return (
    <Fragment>
      <nav className="text-archive navbar navbar-expand-lg shadow sticky-top bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="Bootstrap" width="60" height="60" />
          </a>
          <button className="navbar-toggler" type="button" onClick={toggleOffcanvas} aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse `} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 col-md-12">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page" onClick={closeOffcanvas}>Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/cursos" className="nav-link inactive" onClick={closeOffcanvas}>Cursos</Link>
              </li>
              <li className="nav-item">
                <Link to="/nosotros" className="nav-link active" onClick={closeOffcanvas}>Nosotros</Link>
              </li>
              <div className="col-md-4 col-lg-6 col-xl-7  col-xxl-8">
                <li className="input-group mx-2">
                  <span className="input-group-text rounded-start-pill" id="basic-addon1"><i className="bi bi-search"></i></span>
                  <input 
                    className="form-control rounded-end-pill" 
                    id="search" 
                    type="search" 
                    placeholder="Busca lo que quieras..." 
                    aria-label="Search" 
                    onChange={handleSearchChange}

                  />
                </li>
              </div>

              <li className="nav-item mx-2 col-md-1 col-lg-3 col-xl-2 col-xxl-2 col-lg-2 d-flex justify-content-end mt-1">
                {!user ? (
                  <>
                    <Link to="/login" className="btn btn-danger mx-2 text-white" onClick={closeOffcanvas}>Login</Link>
                    <Link to="/register" className="btn btn-danger text-white" onClick={closeOffcanvas}>Registrate</Link>
                  </>
                ) : (
                  <li className="nav-item dropdown">
                    <a className="text-decoration-none dropdown-toggle text-white" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-person-fill inline-text icon text-white mx-1"></i> {user.data.name}
                    </a>
                    <ul className="dropdown-menu dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                      <Link to="/mycourse" className="dropdown-item" onClick={closeOffcanvas}>Mis Cursos</Link>
                      <li><a className="dropdown-item disabled" href="#">Mi perfil</a></li>
                      <li><a className="dropdown-item" onClick={handleLogout}>Cerrar sesión</a></li>
                    </ul>
                  </li>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Offcanvas navbar for small screens */}
      <div className={`offcanvas offcanvas-end ${isOffcanvasOpen ? 'show' : ''}`} tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menú</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={closeOffcanvas}></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item">
              <Link to="/" className="nav-link active" onClick={closeOffcanvas}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/cursos" className="nav-link" onClick={closeOffcanvas}>Cursos</Link>
            </li>
            <li className="nav-item">
              <Link to="/nosotros" className="nav-link" onClick={closeOffcanvas}>Nosotros</Link>
            </li>
            <div className="col-md-auto col-lg-6 col-xl-7  col-xxl-8">
                <li className="input-group mx-2">
                  <span className="input-group-text rounded-start-pill" id="basic-addon1"><i className="bi bi-search"></i></span>
                  <input 
                    className="form-control rounded-end-pill" 
                    id="search" 
                    type="search" 
                    placeholder="Busca lo que quieras..." 
                    aria-label="Search" 
                    onChange={handleSearchChange}

                  />
                </li>
              </div>
            <li className="nav-item">
              {!user ? (
                <>
                  <Link to="/login" className="btn btn-danger mx-2 text-white" onClick={closeOffcanvas}>Login</Link>
                  <Link to="/register" className="btn btn-danger text-white" onClick={closeOffcanvas}>Registrate</Link>
                </>
              ) : (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle " href="#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-person-fill icon inline-text "></i> {user.data.name}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="offcanvasNavbarDropdown">
                    <Link to="/mycourse" className="dropdown-item" onClick={closeOffcanvas}>Mis Cursos</Link>
                    <li><a className="dropdown-item disabled" href="#">Mi perfil</a></li>
                    <li><a className="dropdown-item" onClick={handleLogout}>Cerrar sesión</a></li>
                  </ul>
                </li>
              )}
            </li>
          </ul>
        </div>
      </div>

      <Outlet />
    </Fragment>
  );
};

export default Navbar;
